import React, { useEffect, useState, useLayoutEffect } from 'react'

import { PageProps } from 'gatsby'
import { parseISO, isBefore } from 'date-fns'
import axios from 'axios'
import { NewsField } from 'src/services/articles/articles'

// Components
import Layout from 'src/components/Layout'
import PageTitle from 'src/components/PageTitle'
import ListArticles from 'src/components/ListArticles/ListArticles'
import categoriesJSON from 'src/assets/data/categories.json'
import trails from 'src/assets/data/trails.json'
import dataJSON from 'src/assets/data/banners.json'
import carousel from 'src/assets/data/video-carousel.json'
import useWidth from 'src/hooks/useWidth'
import CarouselTimerMobile from 'src/components/CarouselTimer/CarouselTimerMobile'
import subcategoriesJSON from 'src/assets/data/subcategories.json'
import HeroNews from 'src/components/HeroNews'
import BestContents from 'src/components/BestContents'
import MarketTendencies from 'src/components/MartketTendencies'
import Stocks from 'src/components/Stocks'
// Types
import { NoticiaType } from 'src/components/News'
// Styles
import SEO from 'src/components/Seo'
import Carousel from 'src/components/Carousel'
import SmallCard from 'src/components/SmallCard'
import CarouselVideos from 'src/components/CarouselVideos'
import bestContentJSON from 'src/assets/data/best-content.json'
import Newsletter from 'src/components/Newsletter'

import Ferramentas from './components/_ferramentas'
// import Investimentos from './components/_investimentos'
import Analise from './components/_analise'

export type Article = {
  id: string
  slug: string
  title: string
  introduction: string
  gridImageUrl: string
  categoryName: string
  publishedAt: string
  updatedAt: string
  shares: number
  spotlight: boolean
  readingTime: number
  subcategoryId: string
  segmentId: string
  categoryId: string
  isFromInset?: boolean
  insetCategory?: string
}

type CategoryProps = {
  pageContext: {
    pageTitle: string
    categoria: string
    allArticles: Article[]
    categoryid: string
    pageSubTitle: string
    icon: string
  }
} & PageProps

const bannerFill2 = {
  showCategoryHeader: false,
  template: 'D',
  dados: bestContentJSON.filter((item) => item.category === 'recomendacoes'),
}
const bannerFill3 = {
  showCategoryHeader: false,
  template: 'D',
  dados: bestContentJSON.filter((item) => item.category === 'analise'),
}

const CategoryTemplate = ({ pageContext }: CategoryProps) => {
  const [isTablet, setIsTablet] = useState(true)
  const allArticlesOrdened = pageContext.allArticles.sort((a, b) => {
    const aDate = parseISO(a.publishedAt)
    const bDate = parseISO(b.publishedAt)
    return isBefore(aDate, bDate) ? 1 : -1
  })

  const [moreShared, setMoreShared] = useState<NoticiaType[]>([])
  const [spotLitghtData, setSpotLitghtData] = useState<GridType[]>([])
  const category = categoriesJSON.find(
    (categoryItem) => categoryItem.slug === pageContext.categoria,
  )
  const breadcrumbPath = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: category?.name,
      link: `/${category?.slug}`,
    },
  ]
  const windowWidth = useWidth(200)
  const [isMobile, setIsMobile] = useState(false)

  useLayoutEffect(() => {
    setIsMobile(windowWidth < 768)
    setIsTablet(windowWidth < 1024)
  }, [windowWidth])

  function normalizeObjectSpotlight(articlesInSpotlight: Article[]) {
    const articlesToSpotLight = articlesInSpotlight.sort((a, b) => {
      const aDate = parseISO(a.publishedAt)
      const bDate = parseISO(b.publishedAt)
      return isBefore(aDate, bDate) ? 1 : -1
    })
    const template = category?.template === 'E' ? 'A' : category?.template
    return {
      template,
      categoryLabel: category?.name,
      category: category?.slug,
      slug: `/${category?.slug}`,
      isCategoryTemplate: true,
      dados: articlesToSpotLight.map((article) => ({
        image: article.homeImage.url,
        alt: article.homeImage.alt,
        slug: article.slug,
        title: article.title,
        subTitle: article.introduction,
        category,
        categorySlug: categoriesJSON.find((item) => item.id === article.categoryId)?.slug,
        subcategorySlug: subcategoriesJSON.find((item) => item.id === article.subcategoryId)?.slug,
        subCategory: subcategoriesJSON.find((item) => item.id === article.subcategoryId)?.name,
        isFromInset: article?.isFromInset,
        insetCategory: article?.insetCategory,
      })),
    }
  }

  const HandleScroll = (id: string) => {
    const section = document.getElementById(id)
    const position = section?.offsetTop || 0
    const poss = position - 187
    window.scroll({
      top: poss,
      behavior: 'smooth',
    })
  }

  const clearLocation = (location: string) => {
    const url = new URL(location)
    const id = url.searchParams.get('id')
    return id
  }

  useEffect(() => {
    const location = clearLocation(window.location.href)

    if (location) {
      setTimeout(() => HandleScroll(location), 100)
    }

    returnArticlesMoreRead()
  }, [])

  useEffect(() => {
    const objectSpotlight = normalizeObjectSpotlight(
      allArticlesOrdened.filter((article) => article.spotlight),
    )
    setSpotLitghtData(objectSpotlight)
  }, [])

  async function returnArticlesMoreRead() {
    try {
      const articles = pageContext.allArticles
      const response = await axios.post(`${process.env.CMS_API}/news/analyticsdata`)
      const { data } = response
      const resposta = data.reduce((all, item: AnalyticsData) => {
        const artigo = articles.find((articleFind) => articleFind.id === item.uuid)
        if (!artigo) {
          return all
        }
        return [...all, NewsField(artigo)]
      }, [])
      setMoreShared(resposta)
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <Layout pageContext={{ categoria: category?.slug }}>
      <SEO
        title={`${category?.name} · Inter Invest`}
        url={`${process.env.BASE_URL}/${category?.slug}`}
        meta={[
          {
            name: 'description',
            content: category?.name,
          },
          {
            property: 'og:title',
            content: `${category?.name} · Inter Invest`,
          },
          {
            property: 'og:description',
            content: category?.name,
          },
          {
            property: 'og:locale',
            content: 'pt_BR',
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:url',
            content: `${process.env.BASE_URL}/${category?.slug}`,
          },
          {
            property: 'og:site_name',
            content: 'Banco Inter',
          },
          {
            property: 'twitter:site',
            content: '@bancointer',
          },
          {
            property: 'twitter:creator',
            content: '@bancointer',
          },
        ]}
      />
      <section>
        <div className="container">
          <div className="row">
            <PageTitle
              breadcrumb={{ path: breadcrumbPath }}
              pageTitle={pageContext.pageTitle}
              pageSubTitle={pageContext.pageSubTitle}
              category={pageContext.categoria}
            />
          </div>
        </div>
      </section>
      <section className="pt-md-4 pb-5">
        {pageContext.categoria !== 'aprenda-a-investir' &&
          Object.keys(spotLitghtData).length > 0 && (
            <div className="container">
              {!isMobile ? (
                <HeroNews data={spotLitghtData.dados.slice(0, 3)} />
              ) : (
                <CarouselTimerMobile category={category?.slug} data={spotLitghtData} />
              )}
            </div>
          )}
      </section>
      <Newsletter page={category?.name} category={category?.name} />
      {/* {pageContext.categoriesId === '1f3a9c83-9600-4620-a28e-fdd9727cdfe7' && (
        // <>{isTablet ? <MarketTendencies /> : <Stocks />}</>
      )} */}
      {pageContext.categoriesId === 'recomendacoes' && bannerFill2.dados.length > 2 && (
        <section className="pt-5 pb-4">
          <div className="container">
            <BestContents bannerFill={bannerFill2} />
          </div>
        </section>
      )}
      {pageContext.categoriesId === '1f3a9c83-9600-4620-a28e-fdd9727cdfe7' && (
        <Ferramentas pageContext={pageContext} />
      )}
      {/* {pageContext.categoria === 'investimentos' && (
        <Investimentos moreShared={moreShared} category={pageContext.categoria} />
      )} */}
      {pageContext.categoria === 'analise' && (
        <>
          <section className="mb-5">
            <Analise />
          </section>
          {bannerFill3.dados.length >= 3 && (
            <section className="mb-5">
              <div className="container">
                <BestContents bannerFill={bannerFill3} />
              </div>
            </section>
          )}
        </>
      )}
      {pageContext.categoria === 'aprenda-a-investir' && (
        <>
          {dataJSON.length > 0 && <Carousel data={dataJSON} />}
          {trails.length > 0 && (
            <div className="mt-5">
              {trails.map((item) => (
                <div key={item.id}>
                  {' '}
                  <SmallCard data={item} />
                </div>
              ))}
            </div>
          )}
          {carousel.id && <CarouselVideos data={carousel} />}
        </>
      )}
      {pageContext.categoria !== 'aprenda-a-investir' && (
        <ListArticles articles={allArticlesOrdened} />
      )}
    </Layout>
  )
}

export default CategoryTemplate
